@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  /* margin: 0;
  padding-left: 12px;
  padding-bottom: 6px; */
  border: none;
  padding: 0;

  @media (--viewportMedium) {
    /* padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px; */
  }
  ul{
    li{
        label{
          span{
            font-family: Sora;
              font-size: 16px;
              font-weight: 400;
              line-height: 25.6px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #818B9C;
              svg{
                
                path{
                  width: 20px;
                  height: 20px;
                }
              }
          }
        }
    }
  }
}

.list {
  margin: 0;
}

.item {
  padding: 4px 0 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
